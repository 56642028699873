import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Spinner, Alert } from 'react-bootstrap';
import api from '../../config/axios';
import { toast } from 'react-toastify';
import ProfileReminder from '../../components/profileReminder';
import { jobTypes, jobCategories, experienceLevels } from '../../utils/constants';

const JobListings = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState('all');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [levelFilter, setLevelFilter] = useState('all');

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const { data } = await api.get('/jobs/public');
      setJobs(data);
    } catch (err) {
      toast.error('Error fetching jobs');
    } finally {
      setLoading(false);
    }
  };

  const filteredJobs = jobs.filter(job => {
    const matchesSearch = job.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.company?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesType = typeFilter === 'all' || job.type === typeFilter;
    const matchesCategory = categoryFilter === 'all' || job.category === categoryFilter;
    const matchesLevel = levelFilter === 'all' || job.experienceLevel === levelFilter;
    
    return matchesSearch && matchesType && matchesCategory && matchesLevel;
  });

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (jobs.length === 0) {
    return (
      <Container className="py-5">
        <Alert variant="info">
          <Alert.Heading>No Jobs Found</Alert.Heading>
          <p>There are currently no job postings available. Please check back later.</p>
        </Alert>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      <ProfileReminder />
      <Row className="mb-4">
        <Col>
          <h1 className="mb-4">Available Positions</h1>
          
          <Row className="g-3">
            <Col md={3}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search jobs..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Select
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
              >
                <option value="all">All Types</option>
                {jobTypes.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={3}>
              <Form.Select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
              >
                <option value="all">All Categories</option>
                {jobCategories.map(category => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={3}>
              <Form.Select
                value={levelFilter}
                onChange={(e) => setLevelFilter(e.target.value)}
              >
                <option value="all">All Levels</option>
                {experienceLevels.map(level => (
                  <option key={level.value} value={level.value}>
                    {level.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          {filteredJobs.map((job) => (
            <Card 
              key={job._id} 
              className="mb-3"
              onClick={() => window.open(`/jobs/${job._id}`, '_blank')}
              style={{ cursor: 'pointer' }}
            >
              <Card.Header className="bg-white">
                <div className="d-flex justify-content-between align-items-start">
                  <div>
                    <h5 className="mb-1">
                      <span className="text-primary">{job.title}</span>
                      <span className="text-muted ms-2" style={{ fontSize: '0.9em' }}>
                        - {job.companyId?.name || 'Company Name Not Available'}
                      </span>
                    </h5>
                    <div className="text-muted small">
                      {job.company && (
                        <span className="me-3">
                          <i className="bi bi-building me-1"></i>
                          {job.company}
                        </span>
                      )}
                      {job.location && (
                        <span className="me-3">
                          <i className="bi bi-geo-alt me-1"></i>
                          {job.location}
                        </span>
                      )}
                      <span>
                        <i className="bi bi-calendar me-1"></i>
                        {formatDate(job.createdAt)}
                      </span>
                    </div>
                  </div>
                </div>
              </Card.Header>
            </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default JobListings;