import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import EditableCard from './components/editableCard';

const initialAchievementState = {
  title: '',
  description: '',
  // Keeping these for API compatibility
  technologies: '',
  url: '',
  startDate: '',
  endDate: '',
};

const AchievementsForm = ({ data, onUpdate }) => {
  const [newAchievement, setNewAchievement] = useState(initialAchievementState);

  // Helper function to ensure data has all required fields
  const ensureValidData = (achievement) => ({
    ...initialAchievementState, // This ensures all fields exist
    ...achievement, // This overwrites with actual data
    // Convert null values to empty strings if they exist
    technologies: achievement.technologies || '',
    url: achievement.url || '',
    startDate: achievement.startDate || '',
    endDate: achievement.endDate || '',
  });

  const handleAdd = () => {
    if (newAchievement.title && newAchievement.description) {
      // Ensure new achievement has all fields
      const formattedAchievement = ensureValidData(newAchievement);
      onUpdate('projects', [...(data.projects || []), formattedAchievement]);
      setNewAchievement(initialAchievementState);
    }
  };

  const handleEdit = (index, updatedAchievement) => {
    const updatedAchievements = [...data.projects];
    // Ensure updated achievement has all fields
    updatedAchievements[index] = ensureValidData(updatedAchievement);
    onUpdate('projects', updatedAchievements);
  };

  const handleDelete = (index) => {
    const updatedAchievements = data.projects.filter((_, i) => i !== index);
    onUpdate('projects', updatedAchievements);
  };

  const renderAchievementForm = (achievement, onChange, onCancel, onSubmit) => {
    // Ensure the achievement data is valid before rendering the form
    const formData = ensureValidData(achievement);

    return (
      <div>
        <Form.Group className="mb-3">
          <Form.Label>Achievement Title</Form.Label>
          <Form.Control
            type="text"
            value={formData.title}
            onChange={(e) => onChange({ ...formData, title: e.target.value })}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={formData.description}
            onChange={(e) =>
              onChange({ ...formData, description: e.target.value })
            }
            required
          />
        </Form.Group>

        <div className="d-flex justify-content-end gap-2">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </div>
      </div>
    );
  };

  const renderAchievementDisplay = (achievement) => {
    // Ensure the achievement data is valid before displaying
    const displayData = ensureValidData(achievement);

    return (
      <>
        <h5 className="mb-1">{displayData.title}</h5>
        <p className="mb-2">{displayData.description}</p>
      </>
    );
  };

  return (
    <div>
      {data.projects?.map((achievement, index) => (
        <EditableCard
          key={index}
          data={ensureValidData(achievement)} // Ensure data is valid before passing to EditableCard
          onEdit={(updatedAchievement) => handleEdit(index, updatedAchievement)}
          onDelete={() => handleDelete(index)}
          renderDisplay={renderAchievementDisplay}
          renderEditForm={renderAchievementForm}
        />
      ))}

      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Add New Achievement</Card.Title>
          {renderAchievementForm(
            newAchievement,
            setNewAchievement,
            () => setNewAchievement(initialAchievementState),
            handleAdd
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default AchievementsForm;
