import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/Button';


export default function RegistrationPageCompany() {

  return (
    <Container className="small-container">
      <div className="mb-3">
        <Button >Personal</Button>
      </div>
      <div className="mb-3">
        <Button >Company</Button>
      </div>
    </Container>
  );
}
