import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import ProfileReminder from '../../components/profileReminder';
import api from '../../config/axios';
import { toast } from 'react-toastify';

export default function PersonalDashboard() {
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    recentApplications: [],
    matchedJobs: [],
    latestEvaluation: null,
    profileCompletion: 0
  });

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const [
        applicationsRes,
        evaluationRes,
        cvRes,
        profileRes
      ] = await Promise.all([
        api.get('/applications/user'),
        api.get('/evaluations/history'),
        api.get('/users/cv'),
        api.get('/users/profile')
      ]);

      // Calculate profile completion
      const cvComplete = cvRes.data?.cv?.status === 'completed';
      const profileComplete = profileRes.data?.user?.employeeProfile?.length > 0;
      const completionScore = ((cvComplete ? 50 : 0) + (profileComplete ? 50 : 0));

      setDashboardData({
        recentApplications: applicationsRes.data.slice(0, 5) || [],
        latestEvaluation: evaluationRes.data[0] || null,
        profileCompletion: completionScore
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      toast.error('Error loading dashboard data');
    } finally {
      setLoading(false);
    }
  };

  const getStatusBadgeVariant = (status) => {
    switch (status) {
      case 'accepted': return 'success';
      case 'rejected': return 'danger';
      case 'pending': return 'warning';
      default: return 'secondary';
    }
  };

  if (loading) {
    return (
      <Container className="mt-3">
        <div className="text-center py-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container className="mt-3">
      <ProfileReminder />
      
      <h1 className="mb-4">My Dashboard</h1>
      
      {/* Profile Completion & Quick Actions */}
      <Row className="mb-4">
        <Col lg={8}>
          <Card className="mb-3">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h5 className="mb-1">Profile Completion</h5>
                  <p className="text-muted mb-0">
                    {dashboardData.profileCompletion}% Complete
                  </p>
                </div>
                <h3 className="mb-0">{dashboardData.profileCompletion}%</h3>
              </div>
              <div className="progress" style={{ height: "10px" }}>
                <div 
                  className="progress-bar bg-success" 
                  style={{ width: `${dashboardData.profileCompletion}%` }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Body>
              <h5 className="mb-3">Quick Actions</h5>
              <div className="d-grid gap-2">
                <Link to="/ai-jobs">
                  <Button variant="primary" className="w-100">
                    View AI Job Matches
                  </Button>
                </Link>
                <Link to="/all-jobs">
                  <Button variant="outline-primary" className="w-100">
                    Browse All Jobs
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Recent Applications */}
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header className="bg-white">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Recent Applications</h5>
                <Link to="/all-jobs" className="btn btn-sm btn-link">
                  View All
                </Link>
              </div>
            </Card.Header>
            <Card.Body>
              {dashboardData.recentApplications.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Position</th>
                        <th>Company</th>
                        <th>Status</th>
                        <th>Applied Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData.recentApplications.map((app) => (
                        <tr key={app._id}>
                          <td>{app.jobId?.title}</td>
                          <td>{app.jobId?.companyId?.name}</td>
                          <td>
                            <Badge bg={getStatusBadgeVariant(app.status)}>
                              {app.status}
                            </Badge>
                          </td>
                          <td>{new Date(app.createdAt).toLocaleDateString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-center text-muted my-4">
                  No applications yet. Start applying to jobs!
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Latest Evaluation */}
      {dashboardData.latestEvaluation && (
        <Row>
          <Col>
            <Card>
              <Card.Header className="bg-white">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Latest AI Evaluation</h5>
                  <Link to="/my-evaluation" className="btn btn-sm btn-link">
                    View Full Evaluation
                  </Link>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <div className="text-center">
                      <h3 className="mb-0">
                        {dashboardData.latestEvaluation.evaluation?.marketFit?.score}%
                      </h3>
                      <p className="text-muted">Market Fit Score</p>
                    </div>
                  </Col>
                  <Col md={8}>
                    <h6>Key Strengths:</h6>
                    <ul className="mb-0">
                      {dashboardData.latestEvaluation.evaluation?.strengths?.slice(0, 3).map((strength, index) => (
                        <li key={index}>{strength}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}