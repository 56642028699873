// components/AIFoundJobCard.js
import React from 'react';
import { Card, Badge, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './aiFoundJobCard.css';

export default function AIFoundJobCard({ match }) {
  const navigate = useNavigate();

  return (
    <Card className="job-card mb-4 shadow-sm">
      <Card.Header className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="job-title mb-0">{match.title}</h5>
            {match.type && (
              <small className="job-type mt-1">{match.type}</small>
            )}
          </div>
          <Badge 
            bg={match.matchScore >= 80 ? 'success' : 
                match.matchScore >= 60 ? 'warning' : 'danger'}
            className="match-score"
          >
            Match Score: {match.matchScore}%
          </Badge>
        </div>
      </Card.Header>
      
      <Card.Body className="p-4">
        <Row>
          <Col lg={6}>
            {/* Skills Analysis Card */}
            <Card className="inner-card mb-3">
              <Card.Body>
                <h6 className="section-title">Skills Analysis</h6>
                
                {/* Matching Skills */}
                {match.skillsAnalysis?.matching?.length > 0 && (
                  <div className="mb-3">
                    <strong>Matching Skills:</strong>
                    <div className="d-flex flex-wrap gap-2 mt-2">
                      {match.skillsAnalysis.matching.map((skill, idx) => (
                        <Badge 
                          key={idx} 
                          bg="success"
                          className="skill-badge"
                          title={`Relevance: ${skill.relevance}`}
                        >
                          {skill.skill} ({skill.level})
                        </Badge>
                      ))}
                    </div>
                  </div>
                )}

                {/* Skills to Develop */}
                {match.skillsAnalysis?.missing?.length > 0 && (
                  <div className="mb-3">
                    <strong>Skills to Develop:</strong>
                    <div className="mt-2">
                      {match.skillsAnalysis.missing.map((skill, idx) => (
                        <div key={idx} className="skill-develop-item">
                          <Badge 
                            bg={skill.importance === 'required' ? 'danger' : 'warning'}
                            className="me-2"
                          >
                            {skill.importance}
                          </Badge>
                          <span>{skill.skill}</span>
                          <small className="text-muted ms-2">
                            (Learning: {skill.learningDifficulty})
                          </small>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Transferable Skills */}
                {match.skillsAnalysis?.transferable?.length > 0 && (
                  <div>
                    <strong>Transferable Skills:</strong>
                    <ul className="transferable-skills">
                      {match.skillsAnalysis.transferable.map((item, idx) => (
                        <li key={idx}>
                          {item.from} → {item.to} ({item.relevance})
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Card.Body>
            </Card>

            {/* Growth Opportunities Card */}
            <Card className="inner-card mb-3">
              <Card.Body>
                <h6 className="section-title">Growth Opportunities</h6>
                <p><strong>Career Path:</strong> {match.growthOpportunities?.careerPath}</p>
                <p><strong>Learning Curve:</strong> {match.growthOpportunities?.learningCurve}</p>
                {match.growthOpportunities?.skills?.length > 0 && (
                  <div className="mb-2">
                    <strong>Skills You'll Gain:</strong>
                    <div className="d-flex flex-wrap gap-2 mt-2">
                      {match.growthOpportunities.skills.map((skill, idx) => (
                        <Badge key={idx} bg="info" className="skill-badge">{skill}</Badge>
                      ))}
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col lg={6}>
            {/* Match Analysis Card */}
            <Card className="inner-card mb-3">
              <Card.Body>
                <h6 className="section-title">Match Analysis</h6>
                
                {match.reasons?.pros?.length > 0 && (
                  <div className="mb-3">
                    <strong className="text-success">Pros:</strong>
                    <ul className="pros-list">
                      {match.reasons.pros.map((pro, idx) => (
                        <li key={idx}>{pro}</li>
                      ))}
                    </ul>
                  </div>
                )}
                
                {match.reasons?.cons?.length > 0 && (
                  <div className="mb-3">
                    <strong className="text-danger">Considerations:</strong>
                    <ul className="cons-list">
                      {match.reasons.cons.map((con, idx) => (
                        <li key={idx}>{con}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {match.reasons?.considerations?.length > 0 && (
                  <div>
                    <strong>Key Points:</strong>
                    <ul className="considerations-list">
                      {match.reasons.considerations.map((point, idx) => (
                        <li key={idx}>{point}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </Card.Body>
            </Card>

            {/* Preparation Steps Card */}
            {match.recommendedPreparation?.length > 0 && (
              <Card className="inner-card">
                <Card.Body>
                  <h6 className="section-title">Recommended Preparation</h6>
                  <ul className="prep-steps">
                    {match.recommendedPreparation.map((step, idx) => (
                      <li key={idx}>{step}</li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>

        {/* Action Buttons */}
        <div className="action-buttons">
          <Button 
            variant="outline-primary"
            className="view-button"
            onClick={() => navigate(`/jobs/${match.jobId}`)}
          >
            View Full Job Details
          </Button>
          
          <Button 
            variant="primary"
            className="apply-button"
            onClick={() => {
              navigate('/applications/new', { 
                state: { jobId: match.jobId, useAI: true }
              });
            }}
          >
            Apply with AI
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}