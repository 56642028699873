import React, { useState, useContext, useEffect } from 'react';
import {
  Container,
  Card,
  Form,
  Button,
  Alert,
  Row,
  Col,
} from 'react-bootstrap';
import { Store } from '../../context/context';
import { toast } from 'react-toastify';
import api from '../../config/axios';
import { getError } from '../../utils/utils';
import CompanyProfileReminder from '../../components/companyProfileReminder';

const CompanySettingsPage = () => {
  const { state } = useContext(Store);
  const { companyInfo } = state;

  // Default settings state without API calls
  const [settings, setSettings] = useState({
    emailNotifications: true,
    applicationAlerts: true,
    profileVisibility: 'public',
    language: 'en',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Simple local state management for settings
  const handleSettingChange = (setting, value) => {
    setSettings((prev) => ({
      ...prev,
      [setting]: value,
    }));
  };

  // Mock save functionality
  const handleSaveSettings = () => {
    toast.success('Settings saved (demo)');
  };

  const handlePasswordChange = (field, value) => {
    setPasswordForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Password must include at least one uppercase letter');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Password must include at least one lowercase letter');
    }
    if (!/[0-9]/.test(password)) {
      errors.push('Password must include at least one number');
    }
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?`~]/.test(password)) {
      errors.push('Password must include at least one special character');
    }
    return errors;
  };

  // Only real API call in the component
  const handlePasswordUpdate = async (e) => {
    e.preventDefault();

    if (passwordForm.newPassword !== passwordForm.confirmPassword) {
      toast.error('New passwords do not match');
      return;
    }

    const passwordErrors = validatePassword(passwordForm.newPassword);
    if (passwordErrors.length > 0) {
      passwordErrors.forEach((error) => toast.error(error));
      return;
    }

    try {
      setLoading(true);
      setError(null);

      await api.put('/companies/password', {
        currentPassword: passwordForm.currentPassword,
        newPassword: passwordForm.newPassword,
      });

      toast.success('Password updated successfully');
      setPasswordForm({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    } catch (err) {
      setError(getError(err));
      toast.error(getError(err));
    } finally {
      setLoading(false);
    }
  };

  if (!companyInfo) {
    return (
      <Container className="py-4">
        <Alert variant="warning">Please log in to access settings</Alert>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      <CompanyProfileReminder />

      <h2 className="mb-4">Company Settings</h2>

      {error && (
        <Alert variant="danger" className="mb-4">
          {error}
        </Alert>
      )}

      <Row>
        <Col md={8}>
          {/* General Settings */}
          <Card className="mb-4">
            <Card.Header>
              <h5 className="mb-0">General Settings</h5>
            </Card.Header>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label>Profile Visibility</Form.Label>
                <Form.Select
                  value={settings.profileVisibility}
                  onChange={(e) =>
                    handleSettingChange('profileVisibility', e.target.value)
                  }
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                  <option value="registered">Registered Users Only</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Language</Form.Label>
                <Form.Select
                  value={settings.language}
                  onChange={(e) =>
                    handleSettingChange('language', e.target.value)
                  }
                >
                  <option value="en">English</option>
                  <option value="lt">Lithuanian</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Timezone</Form.Label>
                <Form.Select
                  value={settings.timezone}
                  onChange={(e) =>
                    handleSettingChange('timezone', e.target.value)
                  }
                >
                  <option value="Europe/Vilnius">Europe/Vilnius (GMT+3)</option>
                  <option value="Europe/London">Europe/London (GMT+1)</option>
                  <option value="America/New_York">
                    America/New_York (GMT-4)
                  </option>
                </Form.Select>
              </Form.Group>
            </Card.Body>
          </Card>

          {/* Notification Settings */}
          <Card className="mb-4">
            <Card.Header>
              <h5 className="mb-0">Notification Settings</h5>
            </Card.Header>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  id="email-notifications"
                  label="Email Notifications"
                  checked={settings.emailNotifications}
                  onChange={(e) =>
                    handleSettingChange('emailNotifications', e.target.checked)
                  }
                />
                <Form.Text className="text-muted">
                  Receive important updates and notifications via email
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  id="application-alerts"
                  label="Application Alerts"
                  checked={settings.applicationAlerts}
                  onChange={(e) =>
                    handleSettingChange('applicationAlerts', e.target.checked)
                  }
                />
                <Form.Text className="text-muted">
                  Get notified when candidates apply to your job postings
                </Form.Text>
              </Form.Group>
            </Card.Body>
          </Card>

          <Button variant="primary" onClick={handleSaveSettings}>
            Save Settings
          </Button>
        </Col>

        <Col md={4}>
          {/* Password Change */}
          <Card>
            <Card.Header>
              <h5 className="mb-0">Change Password</h5>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handlePasswordUpdate}>
                <Form.Group className="mb-3">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={passwordForm.currentPassword}
                    onChange={(e) =>
                      handlePasswordChange('currentPassword', e.target.value)
                    }
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={passwordForm.newPassword}
                    onChange={(e) =>
                      handlePasswordChange('newPassword', e.target.value)
                    }
                    required
                  />
                  <Form.Text className="text-muted">
                    Password must:
                    <ul className="mb-0">
                      <li>Be at least 8 characters long</li>
                      <li>Include at least one uppercase letter</li>
                      <li>Include at least one lowercase letter</li>
                      <li>Include at least one number</li>
                      <li>Include at least one special character</li>
                    </ul>
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={passwordForm.confirmPassword}
                    onChange={(e) =>
                      handlePasswordChange('confirmPassword', e.target.value)
                    }
                    required
                  />
                </Form.Group>

                <Button
                  type="submit"
                  variant="primary"
                  disabled={loading}
                  className="w-100"
                >
                  {loading ? 'Updating...' : 'Update Password'}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanySettingsPage;
