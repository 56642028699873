// JobDetailsPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Spinner, Alert } from 'react-bootstrap';
import api from '../../config/axios';
import { toast } from 'react-toastify';
import ApplicationForm from '../../components/applicationForm';


const JobDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showApplicationForm, setShowApplicationForm] = useState(false);


  useEffect(() => {
    fetchJobDetails();
  }, [id]);

  const fetchJobDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await api.get(`/jobs/public/${id}`);
      setJob(data);
    } catch (err) {
      console.error('Error fetching job details:', err);
      setError(err.response?.data?.message || 'Error fetching job details');
      toast.error(err.response?.data?.message || 'Error fetching job details');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error || !job) {
    return (
      <Container className="py-5">
        <Alert variant="danger">
          <Alert.Heading>Job Not Found</Alert.Heading>
          <p>{error || "The job posting you're looking for doesn't exist or has been removed."}</p>
          <div className="mt-3">
            <Button variant="outline-danger" onClick={() => navigate('/jobs')}>
              Back to Jobs
            </Button>
          </div>
        </Alert>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      <Row className="mb-4">
        <Col>
          <Button 
            variant="outline-primary" 
            className="mb-4"
            onClick={() => navigate('/all-jobs')}
          >
            <i className="bi bi-arrow-left me-2"></i>
            Back to Jobs
          </Button>
        </Col>
      </Row>

      <Card>
        <Card.Header className="bg-white">
          <div className="d-flex justify-content-between align-items-start flex-wrap">
            <div>
              <h2 className="mb-2">{job.title}</h2>
              <div className="text-muted">
                {job.companyId?.name && (
                  <span className="me-3">
                    <i className="bi bi-building me-1"></i>
                    {job.companyId.name}
                  </span>
                )}
                {job.location && (
                  <span className="me-3">
                    <i className="bi bi-geo-alt me-1"></i>
                    {job.location}
                  </span>
                )}
                <span>
                  <i className="bi bi-calendar me-1"></i>
                  Posted on {formatDate(job.createdAt)}
                </span>
              </div>
            </div>
            <Button
      variant="primary"
      size="lg"
      onClick={() => setShowApplicationForm(true)}
    >
      Apply Now
    </Button>
          </div>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col md={8}>
              {/* Job Description */}
              {job.description && (
                <div className="mb-4">
                  <h4 className="mb-3">Description</h4>
                  <div className="text-muted" style={{ whiteSpace: 'pre-line' }}>
                    {job.description}
                  </div>
                </div>
              )}

              {/* Job Requirements */}
              {job.requirements && (
                <div className="mb-4">
                  <h4 className="mb-3">Requirements</h4>
                  <div className="text-muted" style={{ whiteSpace: 'pre-line' }}>
                    {job.requirements}
                  </div>
                </div>
              )}
            </Col>

            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <h5 className="mb-3">Job Details</h5>
                  <div className="mb-3">
                    <strong className="d-block">Job Type</strong>
                    <span className="text-muted">{job.type || 'Not specified'}</span>
                  </div>
                  <div className="mb-3">
                    <strong className="d-block">Status</strong>
                    <span className="text-muted">{job.status || 'Not specified'}</span>
                  </div>
                  <div>
                    <Button
                      variant="primary"
                      className="w-100"
                      onClick={() => setShowApplicationForm(true)}
                    >
                      Apply for this position
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ApplicationForm 
      show={showApplicationForm}
      onHide={() => setShowApplicationForm(false)}
      jobId={job._id}
      jobTitle={job.title}
    />
    </Container>
  );
};

export default JobDetailsPage;