import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Brain, Users, Clock, Target, Award, Lightbulb, ArrowRight, CheckCircle } from 'lucide-react';

const AboutPage = () => {
  const platformFeatures = [
    "Išankstinę naudotojų atranką, remiantis kvalifikacija, patirtimi ir karjeros vizija.",
    "AI algoritmus, kurie suderina įgūdžius, patirtį ir asmenybę, užtikrindami tikslią atranką.",
    "Psichologinius asmenybės testus, įskaitant 16 asmenybių sistemą, kurie padeda įvertinti suderinamumą su įmonės kultūra ir komanda.",
    "Efektyvų, aiškų ir kokybišką įdarbinimo procesą, pritaikytą įvairių poreikių darbuotojams ir darbdaviams."
  ];

  const employerBenefits = [
    {
      title: "Kokybiškesnė atranka",
      desc: "Tik patikimi ir tinkamiausi kandidatai"
    },
    {
      title: "Vadovavimo ir strateginių įgūdžių vertinimas",
      desc: "Galimybė rasti lyderystės ir problemų sprendimo įgūdžius turinčius profesionalus"
    },
    {
      title: "Lankstumas",
      desc: "Patyrę specialistai strateginėms pozicijoms ir jauni talentai augimui bei dinamikai"
    }
  ];

  const employeeBenefits = [
    {
      title: "Tikslūs pasiūlymai",
      desc: "Darbo galimybės, pritaikytos jūsų patirčiai ir tikslams"
    },
    {
      title: "Asmenybės suderinamumas",
      desc: "Vertinama, kaip jūsų asmenybė tinka komandos kultūrai"
    },
    {
      title: "Stabilumas ir augimas",
      desc: "Pozicijos su aiškiomis karjeros vystymosi perspektyvomis"
    },
    {
      title: "Laiko taupymas",
      desc: "Efektyvi ir patogi darbo paieška be nereikalingo triukšmo"
    }
  ];

  return (
    <div className="about-page">
      {/* Hero Section with Border */}
      <div className="hero-section-border">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="hero-content">
                <h1>
                  HirexPro.com
                  <span className="subtitle">Atranka. Ryšys. Darbas.</span>
                </h1>
                <p className="lead">
                  Išmanioji darbo paieškos platforma darbdaviams ir darbuotojams
                </p>
                <p className="description">
                  HirexPro sujungia dirbtinio intelekto galimybes, psichologinius testus 
                  ir pažangius atrankos procesus, kad tiek patyrę profesionalai, tiek 
                  jaunieji talentai rastų pozicijas, kurios geriausiai atitinka jų 
                  patirtį, gebėjimus ir karjeros tikslus.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Platform Features */}
      <section className="features-section">
        <Container>
          <h2 className="section-title">Ką siūlo platforma?</h2>
          <Row className="g-4">
            {platformFeatures.map((feature, index) => (
              <Col md={6} lg={3} key={index}>
                <div className="feature-card">
                  <div className="feature-icon-wrapper">
                    {[<Users />, <Brain />, <Target />, <Award />][index]}
                  </div>
                  <p>{feature}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <Container>
          <Row className="g-4">
            <Col md={6}>
              <div className="benefits-container employer">
                <div className="benefits-header">
                  <Users className="header-icon" />
                  <h3>Darbdaviams</h3>
                </div>
                <div className="benefits-content">
                  {employerBenefits.map((benefit, index) => (
                    <div className="benefit-item" key={index}>
                      <CheckCircle className="check-icon" />
                      <div>
                        <h4>{benefit.title}</h4>
                        <p>{benefit.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="benefits-container employee">
                <div className="benefits-header">
                  <Users className="header-icon" />
                  <h3>Darbuotojams</h3>
                </div>
                <div className="benefits-content">
                  {employeeBenefits.map((benefit, index) => (
                    <div className="benefit-item" key={index}>
                      <CheckCircle className="check-icon" />
                      <div>
                        <h4>{benefit.title}</h4>
                        <p>{benefit.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Psychology Tests Section */}
      <section className="psychology-section">
        <Container>
          <div className="psychology-card">
            <div className="psychology-header">
              <Brain className="psychology-icon" />
              <h3>Psichologinių testų integracija</h3>
            </div>
            <p>
              HirexPro išskirtinai naudoja <strong>16 asmenybių sistemą</strong>, 
              siekiant ne tik įvertinti profesinius gebėjimus, bet ir užtikrinti 
              <strong> ilgalaikį suderinamumą</strong> tarp darbuotojo ir įmonės. 
              Tai padeda sukurti efektyvesnes ir stabilesnes komandas.
            </p>
          </div>
        </Container>
      </section>

      {/* Closing Statement */}
      <section className="closing-section">
        <Container>
          <div className="closing-card">
            <h2>
              <strong>HirexPro</strong> – platforma, kur{' '}
              <span className="highlight">technologijos, patirtis ir psichologiniai sprendimai</span>{' '}
              susijungia tam, kad darbdaviai ir darbuotojai atrastų vieni kitus tiksliai ir efektyviai.
            </h2>
          </div>
        </Container>
      </section>

      <style jsx>{`
        .hero-section-border {
          padding: 4rem 0;
          background: #f8fafc;
        }

        .hero-content {
          padding: 3rem;
          border-left: 6px solid #3b82f6;
          background: white;
          border-radius: 0 12px 12px 0;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
        }

        .hero-content h1 {
          font-size: 3.5rem;
          font-weight: 700;
          color: #1e293b;
          margin-bottom: 0.5rem;
        }

        .subtitle {
          display: block;
          font-size: 1.75rem;
          color: #3b82f6;
          margin-top: 0.5rem;
        }

        .lead {
          font-size: 1.25rem;
          color: #64748b;
          margin-bottom: 1rem;
        }

        .description {
          color: #94a3b8;
          font-size: 1.1rem;
          line-height: 1.6;
        }

        .section-title {
          text-align: center;
          margin-bottom: 3rem;
          color: #1e293b;
          font-size: 2rem;
        }

        .features-section {
          padding: 5rem 0;
          background: white;
        }

        .feature-card {
          background: #f8fafc;
          padding: 2rem;
          border-radius: 12px;
          height: 100%;
          transition: all 0.3s ease;
        }

        .feature-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
        }

        .feature-icon-wrapper {
          width: 48px;
          height: 48px;
          color: #3b82f6;
          margin-bottom: 1.5rem;
        }

        .benefits-section {
          padding: 5rem 0;
          background: #f8fafc;
        }

        .benefits-container {
          height: 100%;
          background: white;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
        }

        .benefits-header {
          padding: 1.5rem;
          background: #1e293b;
          color: white;
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        .employee .benefits-header {
          background: #3b82f6;
        }

        .header-icon {
          width: 24px;
          height: 24px;
        }

        .benefits-content {
          padding: 2rem;
        }

        .benefit-item {
          display: flex;
          gap: 1rem;
          padding: 1rem 0;
          border-bottom: 1px solid #e2e8f0;
        }

        .benefit-item:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        .check-icon {
          width: 24px;
          height: 24px;
          color: #3b82f6;
          flex-shrink: 0;
        }

        .benefit-item h4 {
          font-size: 1.1rem;
          color: #1e293b;
          margin-bottom: 0.5rem;
        }

        .benefit-item p {
          margin: 0;
          color: #64748b;
          font-size: 0.95rem;
        }

        .psychology-section {
          padding: 5rem 0;
          background: white;
        }

        .psychology-card {
          max-width: 800px;
          margin: 0 auto;
          padding: 3rem;
          background: #f8fafc;
          border-radius: 12px;
          text-align: center;
        }

        .psychology-header {
          margin-bottom: 2rem;
        }

        .psychology-icon {
          width: 48px;
          height: 48px;
          color: #3b82f6;
          margin-bottom: 1rem;
        }

        .closing-section {
          padding: 5rem 0;
          background: #f8fafc;
        }

        .closing-card {
          text-align: center;
          max-width: 900px;
          margin: 0 auto;
        }

        .highlight {
          color: #3b82f6;
        }

        @media (max-width: 768px) {
          .hero-content {
            padding: 2rem;
          }

          .hero-content h1 {
            font-size: 2.5rem;
          }

          .subtitle {
            font-size: 1.5rem;
          }

          .benefits-content {
            padding: 1.5rem;
          }
        }
      `}</style>
    </div>
  );
};

export default AboutPage;