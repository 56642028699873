// Pages/AIMatchJobsPage.js
import React, { useState, useContext } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Alert,
  Spinner,
  Badge,
  Row,
  Col,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Store } from '../../context/context';
import { getAIJobMatches } from '../../services/aiService';
import { useNavigate } from 'react-router-dom';
import ProfileReminder from '../../components/profileReminder';
import AIFoundJobCard from '../../components/aiFoundJobCard';

export default function AIMatchJobsPage() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  const [request, setRequest] = useState('');
  const [matches, setMatches] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  React.useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [userInfo, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const result = await getAIJobMatches(request);
      console.log('AI Match Results:', result);
      setMatches(result);
    } catch (err) {
      setError(err.response?.data?.message || 'Error getting job matches');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="py-4">
      <ProfileReminder />
      <Helmet>
        <title>AI Job Matching</title>
      </Helmet>

      <h1 className="mb-4">AI Job Matching</h1>

      <Card className="mb-4">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>What kind of job are you looking for?</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={request}
                onChange={(e) => setRequest(e.target.value)}
                placeholder="Describe your ideal job, including any specific requirements, preferred technologies, work environment, etc."
                required
              />
            </Form.Group>
            <Button type="submit" disabled={loading || !request.trim()}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Finding matches...
                </>
              ) : (
                'Find Matching Jobs'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {error && <Alert variant="danger">{error}</Alert>}

      {matches &&
        matches.matches &&
        matches.matches.map((match, index) => (
          <AIFoundJobCard key={index} match={match} />
        ))}
    </Container>
  );
}
