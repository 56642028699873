import React, { useState, useContext } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Alert,
  Spinner,
  Badge,
  Row,
  Col,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Store } from '../../context/context';
import { getAICandidateMatches } from '../../services/aiService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AIMatchCandidateModal from '../../components/aiMatchCandidateModal';
import CompanyProfileReminder from '../../components/companyProfileReminder';

export default function AICandidateMatchPage() {
  const { state } = useContext(Store);
  const { companyInfo } = state;
  const navigate = useNavigate();

  const [request, setRequest] = useState('');
  const [matches, setMatches] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  React.useEffect(() => {
    if (!companyInfo) {
      navigate('/company/login');
    }
  }, [companyInfo, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      console.log('Submitting request:', request);
      const result = await getAICandidateMatches(request);
      console.log('AI Match Results:', result);
      setMatches(result);
    } catch (err) {
      console.error('Matching error:', err);
      setError(err.message || 'Failed to process candidate matching');
      toast.error(err.message || 'Failed to process candidate matching');
    } finally {
      setLoading(false);
    }
  };

  const handleViewProfile = (candidateId) => {
    const candidate = matches.matches.find(
      (match) => match.candidateId === candidateId
    );
    if (candidate) {
      setSelectedCandidate(candidate);
      setShowModal(true);
    }
  };

  const renderSkillsSection = (match) => {
    // Check if skillsMatch exists and is an array
    if (
      !match.skillsMatch ||
      !Array.isArray(match.skillsMatch) ||
      match.skillsMatch.length === 0
    ) {
      return null;
    }

    return (
      <Col md={6}>
        <h6 className="text-primary">Matching Skills</h6>
        <div className="d-flex flex-wrap gap-2">
          {match.skillsMatch.map((skill, idx) => (
            <Badge key={idx} bg="secondary">
              {skill}
            </Badge>
          ))}
        </div>
      </Col>
    );
  };

  return (
    <Container className="py-4">
      <CompanyProfileReminder />

      <Helmet>
        <title>AI Candidate Matching</title>
      </Helmet>

      <h1 className="mb-4">AI Candidate Search</h1>

      <Card className="mb-4">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>
                What kind of candidate are you looking for?
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={request}
                onChange={(e) => setRequest(e.target.value)}
                placeholder="Describe your ideal candidate, including required skills, experience level, educational background, and any specific qualifications."
                required
              />
            </Form.Group>
            <Button
              type="submit"
              disabled={loading || !request.trim()}
              className="w-100"
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Finding candidates...
                </>
              ) : (
                'Find Matching Candidates'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {error && <Alert variant="danger">{error}</Alert>}

      {matches && (
        <div>
          {matches.summary && (
            <Alert variant="info" className="mb-4">
              <Alert.Heading>Analysis Summary</Alert.Heading>
              <p className="mb-0">{matches.summary}</p>
            </Alert>
          )}

          {matches.matches && matches.matches.length > 0 ? (
            matches.matches.map((match, index) => (
              <Card key={index} className="mb-4">
                <Card.Header className="bg-light">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">
                      {match.candidateDetails?.name ||
                        `Candidate Match #${index + 1}`}
                    </h5>
                    <Badge
                      bg={
                        match.matchScore >= 80
                          ? 'success'
                          : match.matchScore >= 60
                          ? 'warning'
                          : 'danger'
                      }
                      className="fs-6"
                    >
                      Match Score: {match.matchScore}%
                    </Badge>
                  </div>
                </Card.Header>

                <Card.Body>
                  {match.reasons && match.reasons.length > 0 && (
                    <div className="mb-4">
                      <h6 className="text-primary">Why This Match?</h6>
                      <ul className="mb-0">
                        {match.reasons.map((reason, idx) => (
                          <li key={idx}>{reason}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <Row className="mb-4">
                    {renderSkillsSection(match)}

                    {match.experienceMatch && (
                      <Col md={match.skillsMatch?.length ? 6 : 12}>
                        <h6 className="text-primary">Experience Overview</h6>
                        <p className="mb-0">{match.experienceMatch}</p>
                      </Col>
                    )}
                  </Row>

                  {match.cultureFit && (
                    <div className="mb-4">
                      <h6 className="text-primary">Culture Fit Analysis</h6>
                      <p className="mb-0">{match.cultureFit}</p>
                    </div>
                  )}

                  {match.recommendations &&
                    match.recommendations.length > 0 && (
                      <div className="mb-4">
                        <h6 className="text-primary">Recommendations</h6>
                        <ul className="mb-0">
                          {match.recommendations.map((rec, idx) => (
                            <li key={idx}>{rec}</li>
                          ))}
                        </ul>
                      </div>
                    )}

                  <div className="mt-4 text-end">
                    <Button
                      variant="primary"
                      onClick={() => handleViewProfile(match.candidateId)}
                    >
                      View Full Profile
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))
          ) : (
            <Alert variant="warning">No matching candidates found.</Alert>
          )}
        </div>
      )}

      <AIMatchCandidateModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedCandidate(null);
        }}
        candidate={selectedCandidate}
      />
    </Container>
  );
}
