import React from 'react';
import { Container } from 'react-bootstrap';

export default function MainPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-blue-50">
      <Container className="h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-6xl font-light mb-4 text-gray-800">HirexPro</h1>
          <p className="text-xl text-gray-500 font-light">
            AI-Powered Recruitment Platform
          </p>
        </div>
      </Container>
    </div>
  );
}
