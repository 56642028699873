// Components/AIMatchCandidateModal.js
import React from 'react';
import { Modal, Badge, Row, Col, Tabs, Tab, Card, Alert } from 'react-bootstrap';

const AIMatchCandidateModal = ({ show, onHide, candidate }) => {
  if (!candidate) return null;

  // Helper function to format questionnaire type
  const formatQuestionType = (type) => {
    return type
      .replace(/([A-Z])/g, ' $1')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderSkillsMatch = () => {
    if (!candidate.skillsMatch || !Array.isArray(candidate.skillsMatch) || candidate.skillsMatch.length === 0) {
      return null;
    }

    return (
      <Col md={6}>
        <h5>Matching Skills</h5>
        <div className="d-flex flex-wrap gap-2">
          {candidate.skillsMatch.map((skill, idx) => (
            <Badge key={idx} bg="primary">{skill}</Badge>
          ))}
        </div>
      </Col>
    );
  };

  const renderReasons = () => {
    if (!candidate.reasons || !Array.isArray(candidate.reasons) || candidate.reasons.length === 0) {
      return null;
    }

    return (
      <div className="mb-4">
        <h5>Why This Match?</h5>
        <ul className="mb-0">
          {candidate.reasons.map((reason, idx) => (
            <li key={idx} className="mb-2">{reason}</li>
          ))}
        </ul>
      </div>
    );
  };

  const renderRecommendations = () => {
    if (!candidate.recommendations || !Array.isArray(candidate.recommendations) || candidate.recommendations.length === 0) {
      return null;
    }

    return (
      <div>
        <h5>Recommendations</h5>
        <ul className="mb-0">
          {candidate.recommendations.map((rec, idx) => (
            <li key={idx} className="mb-2">{rec}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex align-items-center gap-3">
            {candidate.matchScore !== undefined && (
              <Badge 
                bg={candidate.matchScore >= 80 ? 'success' : 
                    candidate.matchScore >= 60 ? 'warning' : 'danger'}
              >
                Match Score: {candidate.matchScore}%
              </Badge>
            )}
            {candidate.candidateDetails?.name && (
              <span>{candidate.candidateDetails.name}</span>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <Tabs defaultActiveKey="match" className="mb-4">
          {/* Match Analysis Tab */}
          <Tab eventKey="match" title="Match Analysis">
            <div className="py-3">
              {renderReasons()}

              <Row className="mb-4">
                {renderSkillsMatch()}

                {candidate.experienceMatch && (
                  <Col md={candidate.skillsMatch?.length ? 6 : 12}>
                    <h5>Experience Overview</h5>
                    <p className="text-muted">{candidate.experienceMatch}</p>
                  </Col>
                )}
              </Row>

              {candidate.cultureFit && (
                <div className="mb-4">
                  <h5>Culture Fit Analysis</h5>
                  <p>{candidate.cultureFit}</p>
                </div>
              )}

              {renderRecommendations()}
            </div>
          </Tab>

          {/* CV Details Tab */}
          <Tab eventKey="cv" title="CV Details">
            <div className="py-3">
              {/* Skills Section */}
              {candidate.candidateDetails?.cv?.skills?.length > 0 && (
                <div className="mb-4">
                  <h5>Skills</h5>
                  <div className="d-flex flex-wrap gap-2">
                    {candidate.candidateDetails.cv.skills.map((skill, index) => (
                      <Badge 
                        key={index} 
                        bg={skill.level === 'Expert' ? 'primary' : 
                           skill.level === 'Advanced' ? 'success' : 
                           skill.level === 'Intermediate' ? 'info' : 'secondary'}
                      >
                        {skill.name} ({skill.level})
                      </Badge>
                    ))}
                  </div>
                </div>
              )}

              {/* Experience Section */}
              {candidate.candidateDetails?.cv?.experience?.length > 0 && (
                <div className="mb-4">
                  <h5>Experience</h5>
                  {candidate.candidateDetails.cv.experience.map((exp, index) => (
                    <div key={index} className="mb-3">
                      <h6>{exp.position} at {exp.company}</h6>
                      <div className="text-muted small">
                        {new Date(exp.startDate).toLocaleDateString()} - 
                        {exp.current ? 'Present' : new Date(exp.endDate).toLocaleDateString()}
                      </div>
                      <p>{exp.description}</p>
                    </div>
                  ))}
                </div>
              )}

              {/* Education Section */}
              {candidate.candidateDetails?.cv?.education?.length > 0 && (
                <div className="mb-4">
                  <h5>Education</h5>
                  {candidate.candidateDetails.cv.education.map((edu, index) => (
                    <div key={index} className="mb-3">
                      <h6>{edu.degree} in {edu.fieldOfStudy}</h6>
                      <div className="text-muted">
                        {edu.institution}
                        <br />
                        {new Date(edu.startDate).toLocaleDateString()} - 
                        {edu.current ? 'Present' : new Date(edu.endDate).toLocaleDateString()}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* Languages Section */}
              {candidate.candidateDetails?.cv?.languages?.length > 0 && (
                <div>
                  <h5>Languages</h5>
                  <div className="d-flex flex-wrap gap-2">
                    {candidate.candidateDetails.cv.languages.map((lang, index) => (
                      <Badge 
                        key={index}
                        bg="light" 
                        text="dark"
                      >
                        {lang.name} ({lang.level})
                      </Badge>
                    ))}
                  </div>
                </div>
              )}

              {!candidate.candidateDetails?.cv && (
                <Alert variant="info">
                  No CV details available.
                </Alert>
              )}
            </div>
          </Tab>

          {/* Profile Tab */}
          <Tab eventKey="profile" title="Profile Questions">
            <div className="py-3">
              {candidate.candidateDetails?.employeeProfile?.length > 0 ? (
                candidate.candidateDetails.employeeProfile.map((item, index) => (
                  <div key={index} className="mb-4">
                    <h5>{formatQuestionType(item.questionType)}</h5>
                    <Card className="bg-light">
                      <Card.Body>
                        <p className="mb-0">{item.answer}</p>
                      </Card.Body>
                    </Card>
                  </div>
                ))
              ) : (
                <Alert variant="info">
                  No profile questions answered yet.
                </Alert>
              )}
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default AIMatchCandidateModal;