import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  Badge,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import api from '../../config/axios';
import { toast } from 'react-toastify';
import ApplicationDetailsModal from '../../components/applicationDetailsModal';
import CompanyProfileReminder from '../../components/companyProfileReminder';

const ApplicationsManagement = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    try {
      const { data } = await api.get('/applications/company');
      setApplications(data);
    } catch (error) {
      toast.error('Error fetching applications');
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (applicationId, newStatus, note) => {
    try {
      await api.put(`/applications/${applicationId}/status`, {
        status: newStatus,
        note,
      });
      toast.success('Application status updated');
      fetchApplications();
      setShowDetailsModal(false);
    } catch (error) {
      toast.error('Error updating application status');
    }
  };

  const handleViewDetails = (application) => {
    setSelectedApplication(application);
    setShowDetailsModal(true);
  };

  if (loading) {
    return (
      <Container className="text-center py-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="py-4">
      <CompanyProfileReminder />

      <h2 className="mb-4">Applications Management</h2>

      <Form.Select
        className="mb-3"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      >
        <option value="all">All Applications</option>
        <option value="pending">Pending</option>
        <option value="reviewed">Reviewed</option>
        <option value="shortlisted">Shortlisted</option>
        <option value="rejected">Rejected</option>
        <option value="accepted">Accepted</option>
      </Form.Select>

      {applications.filter((app) => filter === 'all' || app.status === filter).length === 0 ? (
        <div className="text-center py-4">
          <h5>No applications found</h5>
          {filter !== 'all' ? (
            <p className="text-muted">Try changing the filter to see other applications</p>
          ) : (
            <p className="text-muted">You haven't received any job applications yet</p>
          )}
        </div>
      ) : (
        <Table responsive>
          <thead>
            <tr>
              <th>Applicant</th>
              <th>Position</th>
              <th>Status</th>
              <th>Applied Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {applications
              .filter((app) => filter === 'all' || app.status === filter)
              .map((application) => (
                <tr key={application._id}>
                  <td>{application.userId.name}</td>
                  <td>{application.jobId.title}</td>
                  <td>
                    <Badge
                      bg={
                        application.status === 'accepted'
                          ? 'success'
                          : application.status === 'rejected'
                          ? 'danger'
                          : application.status === 'shortlisted'
                          ? 'warning'
                          : 'secondary'
                      }
                    >
                      {application.status}
                    </Badge>
                  </td>
                  <td>{new Date(application.createdAt).toLocaleDateString()}</td>
                  <td>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() =>
                        window.open(`/application/${application._id}`, '_blank')
                      }
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      <ApplicationDetailsModal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        application={selectedApplication}
        onUpdateStatus={updateStatus}
      />
    </Container>
  );
};

export default ApplicationsManagement;