// utils/authValidation.js
export const validateRegistrationForm = (formData) => {
    const errors = {};
  
    // Name validation
    if (!formData.name?.trim()) {
      errors.name = 'Name is required';
    } else if (formData.name.length < 2) {
      errors.name = 'Name must be at least 2 characters long';
    } else if (formData.name.length > 50) {
      errors.name = 'Name cannot exceed 50 characters';
    } else if (!/^[a-zA-Z\s-']+$/.test(formData.name)) {
      errors.name = 'Name can only contain letters, spaces, hyphens, and apostrophes';
    }
  
    // Email validation
    if (!formData.email?.trim()) {
      errors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    } else if (formData.email.length > 100) {
      errors.email = 'Email cannot exceed 100 characters';
    }
  
    // Password validation
    if (!formData.password) {
      errors.password = 'Password is required';
    } else {
      const passwordErrors = [];
      if (formData.password.length < 8) {
        passwordErrors.push('Password must be at least 8 characters long');
      }
      if (!/[A-Z]/.test(formData.password)) {
        passwordErrors.push('Include at least one uppercase letter');
      }
      if (!/[a-z]/.test(formData.password)) {
        passwordErrors.push('Include at least one lowercase letter');
      }
      if (!/[0-9]/.test(formData.password)) {
        passwordErrors.push('Include at least one number');
      }
      if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?`~]+/.test(formData.password)) {
        passwordErrors.push('Include at least one special character (!@#$%^&*()_+-=[]{};\'"\\|,.<>/?`~)');
      }
      if (passwordErrors.length > 0) {
        errors.password = passwordErrors;
      }
    }
  
    // Confirm Password validation
    if (formData.confirmPassword !== formData.password) {
      errors.confirmPassword = 'Passwords do not match';
    }
  
    // Company-specific validations
    if (formData.companyCode) {
      if (!formData.companyCode.trim()) {
        errors.companyCode = 'Company code is required';
      } else if (!/^[A-Z0-9]{6,10}$/.test(formData.companyCode)) {
        errors.companyCode = 'Company code must be 6-10 characters long and contain only uppercase letters and numbers';
      }
    }
  
    return {
      isValid: Object.keys(errors).length === 0,
      errors
    };
  };
  
  export const validateLoginForm = (formData) => {
    const errors = {};
  
    // Email validation
    if (!formData.email?.trim()) {
      errors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }
  
    // Password validation
    if (!formData.password) {
      errors.password = 'Password is required';
    }
  
    return {
      isValid: Object.keys(errors).length === 0,
      errors
    };
  };
  
  export const sanitizeFormData = (formData) => {
    const sanitized = {};
    
    // Helper function to sanitize strings
    const sanitizeString = (str) => {
      if (!str) return '';
      // Remove any HTML tags and trim whitespace
      return str.replace(/<[^>]*>?/gm, '').trim();
    };
  
    // Sanitize each field
    Object.keys(formData).forEach(key => {
      if (typeof formData[key] === 'string') {
        sanitized[key] = sanitizeString(formData[key]);
      } else {
        sanitized[key] = formData[key];
      }
    });
  
    return sanitized;
  };
  
  // Rate limiting helper
  const attempts = new Map();
  const MAX_ATTEMPTS = 5;
  const LOCKOUT_TIME = 15 * 60 * 1000; // 15 minutes
  
  export const checkRateLimit = (identifier) => {
    const now = Date.now();
    const userAttempts = attempts.get(identifier) || { count: 0, timestamp: now };
  
    // Reset attempts if lockout period has passed
    if (now - userAttempts.timestamp > LOCKOUT_TIME) {
      attempts.set(identifier, { count: 1, timestamp: now });
      return {
        allowed: true,
        remainingAttempts: MAX_ATTEMPTS - 1
      };
    }
  
    // Increment attempts
    userAttempts.count += 1;
    attempts.set(identifier, userAttempts);
  
    // Check if user has exceeded max attempts
    if (userAttempts.count > MAX_ATTEMPTS) {
      const timeRemaining = Math.ceil((LOCKOUT_TIME - (now - userAttempts.timestamp)) / 1000);
      return {
        allowed: false,
        timeRemaining,
        remainingAttempts: 0
      };
    }
  
    return {
      allowed: true,
      remainingAttempts: MAX_ATTEMPTS - userAttempts.count
    };
  };