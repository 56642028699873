// Pages/CompanyDashboard/CompanyDashboard.js
import React from 'react';
import { Container, Row, Col, Card, Button, Badge  } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import  CompanyProfileReminder  from '../../components/companyProfileReminder';


export default function CompanyDashboard() {
  const navigate = useNavigate();

  return (
    <Container className="py-4">
          <CompanyProfileReminder  />
      <Helmet>
        <title>Company Dashboard</title>
      </Helmet>
      
      <Row>
        <Col md={8} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Quick Actions</Card.Title>
              <Row>
                <Col sm={6} className="mb-3">
                  <Button 
                    variant="primary" 
                    className="w-100"
                    onClick={() => navigate('/job-postings/new')}
                  >
                    <i className="bi bi-plus-circle me-2"></i>
                    Post New Job
                  </Button>
                </Col>
                <Col sm={6} className="mb-3">
                  <Button 
                    variant="outline-primary" 
                    className="w-100"
                    onClick={() => navigate('/candidate-search')}
                  >
                    <i className="bi bi-search me-2"></i>
                    Search Candidates
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="mb-3">
                  <Button 
                    variant="outline-primary" 
                    className="w-100"
                    onClick={() => navigate('/applications/company')}
                  >
                    <i className="bi bi-file-earmark-text me-2"></i>
                    View Applications
                  </Button>
                </Col>
                <Col sm={6} className="mb-3">
                  <Button 
                    variant="outline-primary" 
                    className="w-100"
                    onClick={() => navigate('/ai/match/candidates')}
                  >
                    <i className="bi bi-lightning me-2"></i>
                    AI Matching
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <Card.Title className="mb-0">Recent Job Postings</Card.Title>
                <Button 
                  variant="link" 
                  onClick={() => navigate('/job-postings')}
                >
                  View All
                </Button>
              </div>
              {/* Job postings list will go here */}
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Profile Overview</Card.Title>
              <div className="mb-3">
                <small className="text-muted">Profile Completion</small>
                <div className="progress">
                  <div className="progress-bar" style={{ width: '70%' }}>
                    70%
                  </div>
                </div>
              </div>
              <div className="d-grid">
                <Button 
                  variant="outline-primary"
                  onClick={() => navigate('/company-profile')}
                >
                  <i className="bi bi-person-circle me-2"></i>
                  Complete Profile
                </Button>
              </div>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title className="d-flex justify-content-between align-items-center">
                <span>New Matches</span>
                <Badge bg="primary" pill>12</Badge>
              </Card.Title>
              <div className="text-center py-3">
                <h3 className="mb-0">12</h3>
                <small className="text-muted">Candidates match your requirements</small>
              </div>
              <div className="d-grid gap-2">
                <Button 
                  variant="outline-success"
                  onClick={() => navigate('/ai/match/candidates')}
                >
                  <i className="bi bi-lightning me-2"></i>
                  View AI Matches
                </Button>
                <Button 
                  variant="outline-primary"
                  onClick={() => navigate('/candidate-search')}
                >
                  <i className="bi bi-search me-2"></i>
                  Search Manually
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Additional Quick Stats Row */}
      <Row className="mt-4">
        <Col md={3}>
          <Card className="text-center">
            <Card.Body>
              <h3 className="mb-0">5</h3>
              <small className="text-muted">Active Jobs</small>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center">
            <Card.Body>
              <h3 className="mb-0">23</h3>
              <small className="text-muted">Total Applications</small>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center">
            <Card.Body>
              <h3 className="mb-0">8</h3>
              <small className="text-muted">Pending Review</small>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center">
            <Card.Body>
              <h3 className="mb-0">3</h3>
              <small className="text-muted">Interviews Scheduled</small>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}