// Pages/LoginPageEmployee.js
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Card, Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Store } from '../context/context';
import { toast } from 'react-toastify';
import { getError } from '../utils/utils';
import api from '../config/axios';
import {
  validateLoginForm,
  sanitizeFormData,
  checkRateLimit,
} from '../utils/authValidation';

export default function LoginPageEmployee() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    if (userInfo) {
      navigate('/my-dashboard');
    }
  }, [navigate, userInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Validate form
    const sanitizedData = sanitizeFormData(formData);
    const validation = validateLoginForm(sanitizedData);

    if (!validation.isValid) {
      setErrors(validation.errors);
      const firstError = Object.values(validation.errors)[0];
      toast.error(firstError);
      return;
    }

    // Check rate limiting
    const rateLimit = checkRateLimit(sanitizedData.email);
    if (!rateLimit.allowed) {
      toast.error(
        `Too many login attempts. Please try again in ${Math.ceil(
          rateLimit.timeRemaining / 60
        )} minutes.`
      );
      return;
    }

    try {
      setLoading(true);
      setErrors({});

      const { data } = await api.post('/users/login', {
        email: sanitizedData.email,
        password: sanitizedData.password,
      });

      ctxDispatch({ type: 'USER_LOGIN_SUCCESS', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      navigate('/my-dashboard');
    } catch (err) {
      const errorMsg = getError(err);
      if (err.response?.status === 401) {
        setErrors({ password: 'Invalid email or password' });
      } else {
        toast.error(errorMsg);
      }
      // Update failed attempt count
      checkRateLimit(sanitizedData.email);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="small-container">
      <Helmet>
        <title>Employee Login</title>
      </Helmet>
      <Card className="p-4 my-3">
        <h1 className="mb-4 text-center">Employee Login</h1>
        <Form onSubmit={submitHandler} noValidate>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
              required
              autoComplete="email"
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              isInvalid={!!errors.password}
              required
              autoComplete="current-password"
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" className="w-100" disabled={loading}>
            {loading ? 'Signing in...' : 'Sign In'}
          </Button>
        </Form>
      </Card>
    </Container>
  );
}
