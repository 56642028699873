import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import './navBar.css';

export default function NavBarMain({ userInfo, signoutHandler }) {
  return (
    <Navbar className="modern-navbar" expand="lg" fixed="top">
      <Container>
        <LinkContainer to="/about">
          <Navbar.Brand>
            <i className="bi bi-building nav-icon"></i>
            HirexPro
          </Navbar.Brand>
        </LinkContainer>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/jobs">
              <Nav.Link>
                <i className="bi bi-briefcase nav-icon"></i>
                Browse Jobs
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>
                <i className="bi bi-info-circle nav-icon"></i>
                About
              </Nav.Link>
            </LinkContainer>
            {/* <LinkContainer to="/ai">
              <Nav.Link>
                <i className="bi bi-cpu nav-icon"></i>
                AI Features
              </Nav.Link>
            </LinkContainer> */}
          </Nav>

          <Nav>
            {!userInfo && (
              <>
                <NavDropdown 
                  title={<><i className="bi bi-person-plus nav-icon"></i>Register</>} 
                  id="register-nav-dropdown"
                >
                  <LinkContainer to="/register-employee">
                    <NavDropdown.Item>
                      <i className="bi bi-person nav-icon"></i>
                      Job Seeker
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/register-company">
                    <NavDropdown.Item>
                      <i className="bi bi-building nav-icon"></i>
                      Company
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>

                <NavDropdown 
                  title={<><i className="bi bi-box-arrow-in-right nav-icon"></i>Login</>} 
                  id="login-nav-dropdown"
                >
                  <LinkContainer to="/login-employee">
                    <NavDropdown.Item>
                      <i className="bi bi-person nav-icon"></i>
                      Job Seeker
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/login-company">
                    <NavDropdown.Item>
                      <i className="bi bi-building nav-icon"></i>
                      Company
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </>
            )}

            {userInfo && (
              <div className="profile-section">
                <div className="profile-avatar">
                  <i className="bi bi-person"></i>
                </div>
                <NavDropdown title={userInfo.name} id="user-nav-dropdown">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>
                      <i className="bi bi-person-circle nav-icon"></i>
                      Profile
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={signoutHandler}>
                    <i className="bi bi-box-arrow-right nav-icon"></i>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}