import { useContext } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Store } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import '../navBar.css';

function NavBarCompany() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { companyInfo } = state;

  const signoutHandler = () => {
    ctxDispatch({ type: 'COMPANY_SIGNOUT' });
    localStorage.removeItem('companyInfo');
    navigate('/');
  };

  return (
    <Navbar className="modern-navbar" expand="lg" fixed="top">
      <Container>
        <LinkContainer to="/company-dashboard">
          <Navbar.Brand>
            <i className="bi bi-building nav-icon"></i>
            Company Portal
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="company-navbar-nav" />
        <Navbar.Collapse id="company-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/job-postings">
              <Nav.Link>
                <i className="bi bi-briefcase nav-icon"></i>
                Job Postings
              </Nav.Link>
            </LinkContainer>

            <NavDropdown
              title={
                <>
                  <i className="bi bi-search nav-icon"></i>Talent Search
                </>
              }
              id="search-dropdown"
            >
              <LinkContainer to="/candidate-search">
                <NavDropdown.Item>
                  <i className="bi bi-people nav-icon"></i>
                  Manual Search
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/ai/match/candidates">
                <NavDropdown.Item>
                  <i className="bi bi-cpu nav-icon"></i>
                  AI Match
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            <LinkContainer to="/applications/company">
              <Nav.Link>
                <i className="bi bi-file-text nav-icon"></i>
                Applications
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/analytics">
              <Nav.Link>
                <i className="bi bi-graph-up nav-icon"></i>
                Analytics
              </Nav.Link>
            </LinkContainer>
          </Nav>

          {companyInfo && (
            <div className="profile-section">
              <div className="profile-avatar">
                <i className="bi bi-building"></i>
              </div>
              <NavDropdown title={companyInfo.name} id="company-nav-dropdown">
                <LinkContainer to="/company-dashboard">
                  <NavDropdown.Item>
                    <i className="bi bi-speedometer2 nav-icon"></i>
                    Dashboard
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/company-profile">
                  <NavDropdown.Item>
                    <i className="bi bi-building nav-icon"></i>
                    Company Profile
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/company-settings">
                  <NavDropdown.Item>
                    <i className="bi bi-gear nav-icon"></i>
                    Settings
                  </NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={signoutHandler}>
                  <i className="bi bi-box-arrow-right nav-icon"></i>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarCompany;
