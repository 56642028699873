// App.js
import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Store } from './context/context';
import NavBarPersonal from './components/dashboard/navBarPersonal';
import NavBarMain from './components/navBarMain';

// Import components
import RegistrationPageEmployee from './pages/registration/registrationPageEmployee';
import RegistrationPageCompany from './pages/registration/registrationPageCompany';
import RegistrationPage from './pages/registration/registrationPage';
import LoginPageEmployee from './pages/loginPageEmployee';
import LoginPageCompany from './pages/loginPageCompany';
import PublicJobsPage from './pages/publicJobsPage';

import DashboardPersonal from './pages/personalDashboard/personalDashboard';
import MainPage from './pages/mainPage';
import AboutPage from './pages/aboutPage';
import SuccessStoriesPage from './pages/successStoriesPage';
import Questionnaire from './pages/personalDashboard/questionnaire';
import CVUploadPage from './pages/personalDashboard/cvUploadPage';
import AllJobsPage from './pages/personalDashboard/allJobsPage';
import AIMatchJobsPage from './pages/personalDashboard/aiMatchingPage';
import ProfileInfoPage from './pages/personalDashboard/profileInfoPage';
import SettingsPage from './pages/personalDashboard/settingsPage';
import AiPage from './pages/aiPage';
import CompanyDashboard from './pages/companyDashboard/companyDashboard';
import NavBarCompany from './components/dashboard/navBarCompany';
import JobDetailsPage from './pages/personalDashboard/jobDetailsPage';
import JobPostings from './pages/companyDashboard/jobPostings';
import CompanyProfile from './pages/companyDashboard/companyProfile';
import CandidateSearch from './pages/companyDashboard/candidateSearch';
import AdminDashboard from './pages/adminDashboard/adminDashboard';
import CreateCVPage from './pages/personalDashboard/createCVPage';
import AICandidateMatchPage from './pages/companyDashboard/aiCandidateSearch';
import ApplicationsManagement from './pages/companyDashboard/applicationsManagement';
import ApplicationDetailsPage from './pages/companyDashboard/applicationDetailsPage';
import MyEvaluationPage from './pages/personalDashboard/myEvaluationPage';
import CompanySettingsPage from './pages/companyDashboard/companySettingsPage';
import CompanyAnalytics from './pages/companyDashboard/companyAnalyticsDashboard';

function App() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { companyInfo } = state;

  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="navtop">
        {/* Render appropriate navbar based on auth state */}
        {userInfo ? (
          <NavBarPersonal />
        ) : companyInfo ? (
          <NavBarCompany />
        ) : (
          <NavBarMain />
        )}
      </div>
      <ToastContainer position="bottom-center" limit={1} />

      <main className="flex-grow-1">
        <Container className="mt-5 pt-3">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<MainPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/success" element={<SuccessStoriesPage />} />
            <Route path="/jobs" element={<PublicJobsPage />} />

            <Route
              path="/register-employee"
              element={<RegistrationPageEmployee />}
            />
            <Route
              path="/register-company"
              element={<RegistrationPageCompany />}
            />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/login-employee" element={<LoginPageEmployee />} />
            <Route path="/login-company" element={<LoginPageCompany />} />

            <Route path="/ai" element={<AiPage />} />
            <Route path="/company-dashboard" element={<CompanyDashboard />} />

            {/* User Routes */}
            <Route
              path="/my-dashboard"
              element={
                userInfo ? <DashboardPersonal /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/my-evaluation"
              element={
                userInfo ? <MyEvaluationPage /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/questionnaire"
              element={userInfo ? <Questionnaire /> : <Navigate to="/login" />}
            />
            <Route
              path="/create-cv"
              element={userInfo ? <CreateCVPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/cv-upload"
              element={userInfo ? <CVUploadPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/all-jobs"
              element={userInfo ? <AllJobsPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/ai-jobs"
              element={
                userInfo ? <AIMatchJobsPage /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/jobs/:id"
              element={userInfo ? <JobDetailsPage /> : <Navigate to="/login" />}
            />

            <Route
              path="/profile"
              element={
                userInfo ? <ProfileInfoPage /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/settings"
              element={userInfo ? <SettingsPage /> : <Navigate to="/login" />}
            />

            {/* Company Routes */}
            <Route
              path="/company-dashboard"
              element={
                companyInfo ? <CompanyDashboard /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/job-postings"
              element={companyInfo ? <JobPostings /> : <Navigate to="/login" />}
            />
            <Route
              path="/company-profile"
              element={
                companyInfo ? <CompanyProfile /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/analytics"
              element={
                companyInfo ? <CompanyAnalytics /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/applications/company"
              element={
                companyInfo ? (
                  <ApplicationsManagement />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/application/:id"
              element={
                companyInfo ? (
                  <ApplicationDetailsPage />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/candidate-search"
              element={
                companyInfo ? <CandidateSearch /> : <Navigate to="/login" />
              }
            />

            <Route
              path="/ai/match/candidates"
              element={
                companyInfo ? (
                  <AICandidateMatchPage />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/company-settings"
              element={
                companyInfo ? <CompanySettingsPage /> : <Navigate to="/login" />
              }
            />

            <Route
              path="/admin"
              element={
                companyInfo && companyInfo.isAdmin ? (
                  <AdminDashboard />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            {/* Catch all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Container>
      </main>
    </div>
  );
}

export default App;
