import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Store } from '../context/context';
import api from '../config/axios';

const CompanyProfileReminder = () => {
  const { state } = useContext(Store);
  const { companyInfo } = state;
  const [loading, setLoading] = useState(true);
  const [needsReminder, setNeedsReminder] = useState({
    profile: false,
    jobPostings: false,
    requiredFields: [],
  });

  useEffect(() => {
    const checkCompanyData = async () => {
      if (!companyInfo) {
        setLoading(false);
        return;
      }

      try {
        const profileResponse = await api.get('/companies/profile');
        const jobsResponse = await api.get('/jobs');

        const missingFields = [];
        const profile = profileResponse.data;

        if (!profile.companyName) missingFields.push('company name');
        if (!profile.industry) missingFields.push('industry');
        if (!profile.description) missingFields.push('company description');
        if (!profile.location) missingFields.push('location');

        setNeedsReminder({
          profile: missingFields.length > 0,
          jobPostings: !jobsResponse.data || jobsResponse.data.length === 0,
          requiredFields: missingFields,
        });
      } catch (error) {
        console.error('Error fetching company data:', error);
        setNeedsReminder({
          profile: true,
          jobPostings: true,
          requiredFields: [],
        });
      } finally {
        setLoading(false);
      }
    };

    checkCompanyData();
  }, [companyInfo]);

  if (!companyInfo) return null;

  if (loading) {
    return (
      <div className="text-center my-3">
        <Spinner animation="border" variant="primary" size="sm" />
      </div>
    );
  }

  if (!needsReminder.profile && !needsReminder.jobPostings) return null;

  return (
    <Alert variant="warning" className="mb-4">
      <Alert.Heading>
        <i className="bi bi-exclamation-triangle me-2"></i>
        Complete Your Company Profile
      </Alert.Heading>
      <p>
        Please complete the following to maximize your visibility to potential
        candidates:
      </p>

      <div className="d-flex flex-column gap-3">
        {needsReminder.profile && (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <i className="bi bi-x-circle text-danger me-2"></i>
              Complete your company profile
              {needsReminder.requiredFields.length > 0 && (
                <small className="d-block text-muted ms-4">
                  Missing: {needsReminder.requiredFields.join(', ')}
                </small>
              )}
            </div>
            <Link to="/company-profile">
              <Button size="sm" variant="outline-primary">
                Complete Profile
              </Button>
            </Link>
          </div>
        )}

        {needsReminder.jobPostings && (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <i className="bi bi-x-circle text-danger me-2"></i>
              Add job postings to start recruiting
            </div>
            <Link to="/job-postings">
              <Button size="sm" variant="outline-primary">
                Create Job Post
              </Button>
            </Link>
          </div>
        )}
      </div>

      <hr />
      <p className="mb-0 small text-muted">
        Complete these steps to start finding the perfect candidates for your
        company.
      </p>
    </Alert>
  );
};

export default CompanyProfileReminder;
