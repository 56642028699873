// Context/Context.js
import { createContext, useReducer, useCallback } from 'react';
import { checkRateLimit } from '../utils/authValidation';

export const Store = createContext();

// Secure local storage handling
const secureStorage = {
  getItem: (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(`Error reading ${key} from localStorage:`, error);
      return null;
    }
  },

  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error writing ${key} to localStorage:`, error);
    }
  },

  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing ${key} from localStorage:`, error);
    }
  }
};

const initialState = {
  userInfo: secureStorage.getItem('userInfo'),
  companyInfo: secureStorage.getItem('companyInfo'),
  loading: false,
  error: null,
  lastAuthAttempt: null
};

function reducer(state, action) {
  switch (action.type) {
    // User Registration cases
    case 'USER_REGISTER_REQUEST':
      return { ...state, loading: true, error: null };
      
    case 'USER_REGISTER_SUCCESS':
      secureStorage.setItem('userInfo', action.payload);
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
      
    case 'USER_REGISTER_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
        lastAuthAttempt: Date.now()
      };

    // User Login cases
    case 'USER_LOGIN_REQUEST':
      return { ...state, loading: true, error: null };
      
    case 'USER_LOGIN_SUCCESS':
      secureStorage.setItem('userInfo', action.payload);
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
        lastAuthAttempt: null
      };
      
    case 'USER_LOGIN_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
        lastAuthAttempt: Date.now()
      };

    // Company Registration cases
    case 'COMPANY_REGISTER_REQUEST':
      return { ...state, loading: true, error: null };
      
    case 'COMPANY_REGISTER_SUCCESS':
      secureStorage.setItem('companyInfo', action.payload);
      return {
        ...state,
        loading: false,
        companyInfo: action.payload,
        error: null,
      };
      
    case 'COMPANY_REGISTER_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
        lastAuthAttempt: Date.now()
      };

    // Company Login cases
    case 'COMPANY_LOGIN_REQUEST':
      return { ...state, loading: true, error: null };
      
    case 'COMPANY_LOGIN_SUCCESS':
      secureStorage.setItem('companyInfo', action.payload);
      if (action.payload.email) {
        secureStorage.setItem('companyEmail', action.payload.email);
      }
      return {
        ...state,
        loading: false,
        companyInfo: action.payload,
        error: null,
        lastAuthAttempt: null
      };
      
    case 'COMPANY_LOGIN_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
        lastAuthAttempt: Date.now()
      };

    // Company Profile Update cases
    case 'COMPANY_PROFILE_UPDATE_REQUEST':
      return { ...state, loading: true };
      
    case 'COMPANY_PROFILE_UPDATE_SUCCESS':
      const updatedCompanyInfo = {
        ...state.companyInfo,
        ...action.payload,
      };
      secureStorage.setItem('companyInfo', updatedCompanyInfo);
      return {
        ...state,
        loading: false,
        companyInfo: updatedCompanyInfo,
        error: null,
      };
      
    case 'COMPANY_PROFILE_UPDATE_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // User Signout
    case 'USER_SIGNOUT':
      secureStorage.removeItem('userInfo');
      return {
        ...state,
        userInfo: null,
        loading: false,
        error: null,
      };

    // Company Signout
    case 'COMPANY_SIGNOUT':
      secureStorage.removeItem('companyInfo');
      secureStorage.removeItem('companyEmail');
      return {
        ...state,
        companyInfo: null,
        loading: false,
        error: null,
      };

    // CV Update cases
    case 'CV_UPDATE_REQUEST':
      return { ...state, loading: true };

    case 'CV_UPDATE_SUCCESS':
      const updatedUserInfo = {
        ...state.userInfo,
        cv: action.payload,
      };
      secureStorage.setItem('userInfo', updatedUserInfo);
      return {
        ...state,
        loading: false,
        userInfo: updatedUserInfo,
      };

    case 'CV_UPDATE_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'CLEAR_ERROR':
      return { ...state, error: null };

    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Enhanced dispatch with rate limiting
  const secureDispatch = useCallback((action) => {
    // Check for auth attempts
    if (
      action.type.includes('LOGIN') || 
      action.type.includes('REGISTER')
    ) {
      const identifier = action.payload?.email;
      if (identifier) {
        const rateLimit = checkRateLimit(identifier);
        if (!rateLimit.allowed) {
          dispatch({
            type: `${action.type.split('_')[0]}_LOGIN_FAIL`,
            payload: `Too many attempts. Please try again in ${Math.ceil(rateLimit.timeRemaining / 1)} minute.`
          });
          return;
        }
      }
    }

    dispatch(action);
  }, []);

  const value = { state, dispatch: secureDispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}