import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Store } from '../../context/context';

export default function PersonalInfoForm({ data, onUpdate }) {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const handleChange = (e) => {
    onUpdate('personalInfo', {
      ...data.personalInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          value={userInfo?.email || ''}
          disabled
          className="bg-light"
        />
        <Form.Text className="text-muted">
          Email is linked to your account
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          name="firstName"
          value={data.personalInfo?.firstName || ''}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          name="lastName"
          value={data.personalInfo?.lastName || ''}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="tel"
          name="phoneNumber"
          value={data.personalInfo?.phoneNumber || ''}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>LinkedIn Profile</Form.Label>
        <Form.Control
          type="url"
          name="linkedIn"
          value={data.personalInfo?.linkedIn || ''}
          onChange={handleChange}
          placeholder="https://linkedin.com/in/yourprofile"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>About Me</Form.Label>
        <Form.Control
          as="textarea"
          name="aboutMe"
          rows={4}
          value={data.personalInfo?.aboutMe || ''}
          onChange={handleChange}
          placeholder="Write a brief introduction about yourself, your career goals, and what makes you unique..."
        />
        <Form.Text className="text-muted">
          This will appear at the top of your CV. Make it engaging and
          professional.
        </Form.Text>
      </Form.Group>
    </div>
  );
}
