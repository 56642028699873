// src/Pages/Registration/RegistrationPageEmployee.js
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Card, Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Store } from '../../context/context';
import { toast } from 'react-toastify';
import { getError } from '../../utils/utils';
import api from '../../config/axios';
import { API_ENDPOINTS } from '../../utils/constants';
import {
  validateRegistrationForm,
  sanitizeFormData,
} from '../../utils/authValidation';

export default function RegistrationPageEmployee() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { dispatch: ctxDispatch } = useContext(Store);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Validate form
    const sanitizedData = sanitizeFormData(formData);
    const validation = validateRegistrationForm(sanitizedData);

    if (!validation.isValid) {
      setErrors(validation.errors);
      // Show first error in toast
      const firstError = Object.values(validation.errors)[0];
      const errorMessage = Array.isArray(firstError)
        ? firstError[0]
        : firstError;
      toast.error(errorMessage);
      return;
    }

    try {
      setLoading(true);
      setErrors({});

      const { data } = await api.post(API_ENDPOINTS.REGISTER, {
        name: sanitizedData.name,
        email: sanitizedData.email,
        password: sanitizedData.password,
      });

      ctxDispatch({ type: 'USER_REGISTER', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      navigate('/login-employee');
    } catch (error) {
      const errorMessage = getError(error);
      // Handle specific error cases
      if (error.response?.status === 409) {
        setErrors({ email: 'This email is already registered' });
      } else {
        toast.error(errorMessage);
      }
      console.error('Registration error details:', error.response?.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="small-container">
      <Helmet>
        <title>Employee Registration</title>
      </Helmet>
      <Card className="p-4 my-3">
        <h1 className="mb-4 text-center">Employee Registration</h1>
        <Form onSubmit={submitHandler} noValidate>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={!!errors.name}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              isInvalid={!!errors.password}
              required
            />
            {errors.password && Array.isArray(errors.password) ? (
              <ul className="text-danger small mt-1">
                {errors.password.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            ) : (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              isInvalid={!!errors.confirmPassword}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword}
            </Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" className="w-100" disabled={loading}>
            {loading ? 'Registering...' : 'Register'}
          </Button>
        </Form>
      </Card>
    </Container>
  );
}
